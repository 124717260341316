import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

export default function utilerias() {
	const msgToast = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast) => {
		  toast.addEventListener('mouseenter', Swal.stopTimer)
		  toast.addEventListener('mouseleave', Swal.resumeTimer)
		}
	})

	const msjConfirmarGuardar = Swal.mixin({
		title: '¿Estás seguro de guardar el elemento?',
		icon: 'question',
		allowOutsideClick: false,
		allowEscapeKey: false,
		focusCancel: true,
		showCancelButton: true,
		confirmButtonText: 'Si',
		//confirmButtonColor: '#3085d6',
		cancelButtonText: 'No',
		//cancelButtonColor: '#d33',
	})

	const msjConfirmarEditar = Swal.mixin({
		title: '¿Estás seguro de editar el elemento?',
		icon: 'question',
		allowOutsideClick: false,
		allowEscapeKey: false,
		focusCancel: true,
		showCancelButton: true,
		confirmButtonText: 'Si',
		//confirmButtonColor: '#3085d6',
		cancelButtonText: 'No',
		//cancelButtonColor: '#d33',
	})

	const msjConfirmarCancelarGuardar = Swal.mixin({
		title: '¿Estás seguro de cancelar el registro del elemento?',
		icon: 'question',
		allowOutsideClick: false,
		allowEscapeKey: false,
		focusCancel: true,
		showCancelButton: true,
		confirmButtonText: 'Si',
		//confirmButtonColor: '#3085d6',
		cancelButtonText: 'No',
		//cancelButtonColor: '#d33',
	})

	const msjConfirmarCancelarEditar = Swal.mixin({
		title: '¿Estás seguro de cancelar la edición del elemento?',
		icon: 'question',
		allowOutsideClick: false,
		allowEscapeKey: false,
		focusCancel: true,
		showCancelButton: true,
		confirmButtonText: 'Si',
		//confirmButtonColor: '#3085d6',
		cancelButtonText: 'No',
		//cancelButtonColor: '#d33',
	})

	const msjConfirmarDesactivar = Swal.mixin({
		title: 'El elemento que estas intentando desactivar puede estar asociado a uno o varios registros dentro del sistema.¿Deseas continuar?',
		icon: 'warning',
		allowOutsideClick: false,
		allowEscapeKey: false,
		focusCancel: true,
		showCancelButton: true,
		confirmButtonText: 'Si',
		//confirmButtonColor: '#3085d6',
		cancelButtonText: 'No',
		//cancelButtonColor: '#d33',
	})

	return {
		msgToast,
		msjConfirmarGuardar,
		msjConfirmarEditar,
		msjConfirmarCancelarGuardar,
		msjConfirmarCancelarEditar,
		msjConfirmarDesactivar,
	}
}
